@font-face {
  font-family: 'MHeiHK';
  font-style: normal;
  font-weight: 100;
  src: url('./MHeiHK-Light.woff') format('woff');
}

@font-face {
  font-family: 'MHeiHK';
  font-style: normal;
  font-weight: 500;
  src: url('./MHeiHK-Medium.woff') format('woff');
}

@font-face {
  font-family: 'MHeiHK';
  font-style: normal;
  font-weight: bold;
  src: url('./MHeiHK-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MHeiHK';
  font-style: normal;
  font-weight: 900;
  src: url('./MHeiHK-Xbold.woff') format('woff');
}
