.container {
  width: 1240px;
  height: 1748px;
  background: #f7f6ef;
}

.padding {
  padding: 70px;
}
.fwxl {
  font-weight: 800;
}

.fwlg {
  font-weight: 700;
}

.fwmd {
  font-weight: 500;
}
.fs10xl {
  font-size: 42px;
}
.fs5xl {
  font-size: 32px;
}
.fs4xl {
  font-size: 30px;
}
.fs3xl {
  font-size: 28px;
}
.fs2xl {
  font-size: 26px;
}
.fsxl {
  font-size: 24px;
}
.fslg {
  font-size: 22px;
}

.fsmd {
  font-size: 20px;
}

.fssm {
  font-size: 18px;
}

.fsxs {
  font-size: 16px;
}

.fsxxs {
  font-size: 14px;
}
/* *** 님의 맞춤형 ** (??)ml */
.top {
  padding-top: 35px;
}

.name1 {
  margin: 0;
  border-bottom: 1.5px solid #000000;
  /* line-height: 1; */
}
.name2 {
  font-weight: 500;
}

/* 이미지 */
.middle {
  margin-top: 45px;
  margin-bottom: 48px;
}

.img {
  width: 100%;
}

/* 맞춤형 조제 레시피 */
.bottom {
}

.bottom_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 3px solid #000;
}

.bottom_title {
  margin-left: 3px;
}

.bottom_content {
}

.tableTr {
  width: 100%;
  display: flex;
}

.tdLeft {
  width: 40%;
  display: block;
}

.tdRight {
  width: 60%;
  display: block;
}

.tableRow {
  width: 100%;
  padding-block: 22.5px;
  border-bottom: 1px solid #231f20;
}

.noneBorder {
  border: none;
}

.image {
  width: 100%;
  /* 추가 스타일 작성 */
}
