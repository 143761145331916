.container {
  width: 1240px;
  height: 1748px;
  background: #f7f6ef;
  position: relative;
}

.padding {
  padding: 70px;
}

.fwlg {
  font-weight: 700;
}

.fwmd {
  font-weight: 500;
}

.fs5xl {
  font-size: 32px;
}
.fs4xl {
  font-size: 30px;
}
.fs3xl {
  font-size: 28px;
}
.fs2xl {
  font-size: 26px;
}
.fsxl {
  font-size: 24px;
}
.fslg {
  font-size: 22px;
}

.fsmd {
  font-size: 18px;
}

.fssm {
  font-size: 16px;
}

.fsxs {
  font-size: 14px;
}

.fsxxs {
  font-size: 12px;
}

/* 진단코드, 제조번호, 제조일자, 사용기한 */

/* mbti */
.middle_top {
  height: 300px;
  margin: 2px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative {
  width: 300px;
  height: 100%;
  position: relative;
  /* left: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  position: absolute;
}
.circle img {
  height: 280px;
}
.mbti {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 80px;
  /* left: 85px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mbti_top {
}
.mbti_top img {
  width: 54px;
}
.mbti_bottom {
}
.mbti_bottom img {
  width: 54px;
}
/* mbti summary */
.middle_bottom {
  width: 100%;
  height: 250px;
  border: 1px solid #000;
  padding: 40px;
  display: flex;
  margin-bottom: 40px;
}

.summary_left {
  width: 40%;
}
.smtr {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.smtd_left {
  width: 40%;
}

.smtd_right {
  width: 60%;
}

.summary_right {
  width: 55%;
  border-left: 1px solid #000;
  padding-left: 30px;
}
/* info */
.bottom_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 3px solid #000;
}

.bottom_title {
  margin-left: 3px;
}

.bottom_content {
}

.tableTr {
  width: 100%;
  display: flex;
}

.tdLeft {
  width: 30%;
  display: block;
}

.tdRight {
  width: 70%;
  display: block;
}

.tableRow {
  width: 100%;
  padding-block: 22.5px;
  border-bottom: 1px solid #231f20;
}

.noneBorder {
  border: none;
}

.image {
  width: 100%;
  /* 추가 스타일 작성 */
}

/* 전성분 */
.allIngredient {
  margin-top: 30px;
  font-size: 14px;
}

/* footer */
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 250px;
  background-color: #fff;
  padding: 50px;
}
.footer_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
.footer_content_2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  align-items: flex-end;
}
.footer_left {
  width: 30%;
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.footer_left_2 {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.lillyshopQr {
  width: 95px;
  height: 95px;
}
.qrText {
  margin: 0;
}
.footer_right {
}
.footer_right img {
  width: 150px;
}
.ftText {
}
