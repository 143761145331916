.getListTabs {
  /* background: yellow; */
  /* padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md); */
  position: relative;
}

.getListContainer {
  padding: 1rem 10rem 1rem 10rem; /* 기본값 */
}

@media (max-width: 1200px) {
  .getListContainer {
    padding: 1rem 5rem 1rem 5rem; /* 웹 */
  }
}

@media (max-width: 768px) {
  .getListContainer {
    padding: 1rem 2rem 1rem 2rem; /* 태블릿 */
  }
}

@media (max-width: 480px) {
  .getListContainer {
    padding: 1rem 1rem 1rem 1rem; /* 모바일 */
  }
}
